<template>
  <router-view />
</template>

<script>
export default {
  components: {},
  name: "App",
  mounted() {
//build
  },
  methods: {
    //build
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Atma");
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.cdnfonts.com/css/inter");

@font-face {
  font-family: "Athena";
  src: url("@/assets/fonts/Athena.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.twentyPxFont
{
  font-size: 20px;
}
.sixteenPxFont
{
  font-size: 16px;

}
.fourteenPxFont
{
  font-size: 14px;
}
.tenPxFont
{
  font-size: 10px;
}
#FilterIconOfTable {
  cursor: pointer;
}
/* for info modal used*/
.ellipsis {
  max-width: 150px; /* Adjust width as necessary */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Global Scrollbar Style */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track background */
}

/* Scrollbar hover effect */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker when hovered */
}

.form-select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}

/* Adjust each skeleton box to fill the table cell */
.skeleton-box {
  width: 100%;
  height: 20px; /* Match the typical row height */
  background-color: #ccc;

  border-radius: 4px;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.card,
.LeadChart,
.otherChart,
.LeadChart2 {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Enhanced shadow */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.card:hover,
.LeadChart:hover,
.otherChart:hover,
.LeadChart2:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  cursor: pointer;
}

.modal {
  font-family: "Inter", sans-serif;
}

table tbody tr {
  cursor: pointer;
}

.confirmationButton {
  position: relative;
  padding: 8px 16px;
  background: #4884f7;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.confirmationButton:active {
  background: #8ab2ea;
}

.confirmationButton__text {
  transition: all 0.2s;
}

.confirmationButton--loading .confirmationButton__text {
  visibility: hidden;
  opacity: 0;
}

.confirmationButton--loading::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
.floor-plan-container {
  display: flex;
  align-items: center;
}

.deliveredText {
  color: #32587a;
}

.semi-deliveredText {
  color: #6d756b;
}

.orderedText {
  color: #2381f3;
}

.rejectedText {
  color: #ff0101;
}

.pendingText {
  color: #ffd159;
}

.approvedText {
  color: #38b525;
}

.blue-dot {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  margin-right: 8px;
}

.dropdown-menu {
  z-index: 9999;
}
.dropdown-menu p {
  font-size: 14px;
  padding: 1px 4px;
}

.dropdown-menu p:hover {
  background-color: #ccc;
  cursor: pointer;
  border-radius: 5px;
}

.saveButton {
  letter-spacing: 2px;
  margin: 5px 10px;
  background-color: #b1c5df;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #000000;
}
.browse {
  letter-spacing: 2px;
  margin: 5px 10px;
  background-color: #2381f3;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
}
.cancelButton {
  letter-spacing: 2px;
  margin: 5px 10px;
  background-color: #d7d7d7;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #000000;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 14px;
  height: 13px;
  border: 2px solid #000000; /* Default border color */
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  position: relative;
}

/* Style the checkbox when checked */
.custom-checkbox input[type="checkbox"]:checked {
  background-color: #ffffff; /* Change the background color when checked */
  border-color: #2381f3; /* Change the border color when checked */
}

/* Style the checkmark inside the checkbox when checked */
.custom-checkbox input[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode character for checkmark */
  color: #2381f3; /* Color of the checkmark */
  font-size: 12px; /* Size of the checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
}
body {
  padding: 0;
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #bcbfc6 !important;
  font-family: "Inter", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

.content-Container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #000000;
  font-family: "Inter", sans-serif;
}

.containerChild {
  height: calc(100% - 74px);
  width: calc(100% - 74px);
  margin: 12px 12px 12px 62px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
}
pre {
  font-family: "Inter", sans-serif;
}

.containerChild::-webkit-scrollbar {
  width: 12px;
  display: none;
}

.paginationOfTable {
  display: flex;

  justify-content: space-between;
  align-items: center;
  position: absolute;
  position: fixed;
  bottom: 2%;
  padding: 0.5% 0;
  z-index: 999;
  font-size: 12px;
  border-radius: 0 0 15px 15px;
  background-color: #ffffff;
  width: calc(100% - 74px);
}

.entries-info {
  position: fixed;
  right: 2%;
  white-space: nowrap;
}

.entries-info span {
  background-color: #f8f8f8;
  padding: 1%;
  border-radius: 5px;
  font-size: 14px;

  /* Adjust the values as needed */
}

.pageControlsTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}

.pageControlsTable img {
  margin: 0 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.pageControlsTable button:hover {
  background-color: #eee;
}

.page-numbers span {
  margin: 5px;
  cursor: pointer;

  /* Adjust color for page numbers */
}

.page-number {
  cursor: pointer;
  margin-right: 5px;
  color: #868686;
  font-weight: 500;
}

.current-page {
  color: #000000;
  font-weight: bolder;
}

.tableFormat thead th {
  font-size: 16px;
  font-weight: 500;
}

.tableFormat tbody td {
  font-size: 14px;
}

.bodyBgcolor {
  background-color: #ffffff;
}
.max {
  font-size: 40px;
}
.mainHeadingUnderline {
  font-size: 20px;
  color: #000000;
  font-weight: bolder;
  text-decoration: underline;
}
.mainHeading {
  font-size: 20px;
  color: #000000;
  font-weight: bolder;
}
.inactive-SubHeading {
  font-size: 14px;
  color: #868686;
  font-weight: 500;
}
.active-SubHeading {
  font-size: 14px;
  text-decoration: underline;
  color: #000000;
  font-weight: 500;
}
.blueactive-SubHeading {
  font-size: 14px;

  color: #000000;
  font-weight: 500;
  /* Set left 10% background color here */
  background-image: linear-gradient(to right, #2381f3 3px, transparent 3px);
  background-size: 100% 100%; /* Ensure gradient fills entire width */
  /* You can adjust other styles as needed */
}
.e-underline {
  text-decoration: underline;
  color: #2381f3; /* Set the color for the underline */
  font-size: 14px;
}

.subHeadingHeader {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}
.subHeadingHeader-active {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}
.subHeadingHeader-inactive {
  font-size: 16px;
  color: #868686;
  font-weight: 500;
}
.WhitetableHeaderHeading {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
}
.tableHeaderHeading {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}
.purpleText {
  color: purple;
  font-size: 14px;
}

.subHeaderHeadingBgColor {
  background-color: #bcbfc6;
}
.SubheadingUnderLine {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: 1px;
}
.bodyText {
  font-size: 14px;
  color: #000000;

  font-family: "Inter", sans-serif;
}
.tableTextEvenRow {
  background-color: #f9f9f9;
}

.blackBgColor {
  background-color: #1e2125;
}

.HeaderofApp {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  height: 50px;
  display: flex;
  width: 100%;
  z-index: 1;
  position: relative;
}

.header .div1 {
  width: 23%;
  height: 100%;
  display: flex;
  align-items: center;
}

.header .div1 h3 {
  color: #ffffff;

  text-align: left;
  margin-left: 1px;
}
.arivuLogo {
  margin: 0 10px;
}
.header .div2 {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div2 .searchBar {
  width: 65%;
  height: 65%;
  border-radius: 25px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #ffffff;
}

.div2 .searchIcon {
  margin: 20px 10px;
  width: 20px;
  height: 20px;
}

.div2 .searchBar input {
  width: 80%;
  height: 90%;
  text-align: left;
  background-color: transparent;
  border-radius: 25px;
  color: #ffffff;
  border: none;
  padding-left: 20px;
  font-size: 1.2rem;
  font-family: "Inter", sans-serif;
}

.div2 .searchBar input:focus {
  border: none;
  outline: none;
}

.header .div3 {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 10px;
}

.header .dot {
  height: 30px;
  width: 30px;
  background-color: #2381f3;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.div3 img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #000000;
  padding: 4px 8px;
  background-color: #f8f8f8;
  font-size: 10px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-content:before {
  content: "";
  position: absolute;
  right: 8px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #2381f3 transparent;
  z-index: 9999;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f8f8f8;
  border-radius: 5px;
  color: #000000;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-Menu {
  position: relative;
  display: inline-block;
  z-index: 9999;
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #000000;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 2px;
  border-radius: 15px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  padding: 10px;
}

.dropdown-content-Menu {
  display: none;
  position: absolute;
  right: 0;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 36px;
  border-radius: 15px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  padding: 10px 10px 10px 10px;
  letter-spacing: 2px;
  background-color: #1e2125;
  color: #ffffff;
}

/* Links inside the dropdown */
.dropdown-content-Menu a {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  padding: 12px 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-content-Menu:before {
  content: "";
  position: absolute;
  right: 30px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #000000 transparent;
  z-index: 9999;
}

.menu-item .icon {
  border: none;
  outline: none;
}

/* Change color of dropdown links on hover */
.dropdown-content-Menu #menuItemHeader:hover {
  background-color: #396e76;
  border-radius: 10px;
  color: black;
}

/* Show the dropdown menu on hover */
.dropdown-Menu:hover .dropdown-content-Menu {
  display: block;
  flex-direction: row;
  position: absolute;
}

.menuItems {
  display: flex;
  flex-direction: column;
}

.menu-row {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}

.plusRegister {
  width: 35px;
  height: 35px;
}

.menu-item a {
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  text-decoration: none;
  margin-top: 5px;
}

.menu-item a:hover {
  border-radius: 10px;
  color: black;
}
#initials {
  justify-content: center;
  margin-top: 25%;
  font-size: 16px;
}
.nav-content {
  width: 50px;
  position: fixed;
  overflow-y: auto;
  z-index: 1;
  top: 50px;
  left: 0;
  bottom: 0;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  letter-spacing: 2px;
}

.nav-content .customerSupport {
  display: flex;
  justify-self: last baseline;
  margin-top: 300px;
}

.FeatureList {
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.FeatureList::-webkit-scrollbar {
  width: 0px;
}

.FeatureList::-webkit-scrollbar-thumb {
  background-color: #396e76;
  border-radius: 10px;
}

.nav-content ul {
  justify-self: start;
  list-style-type: none;
  padding: 4px;
  margin: 0;
  position: relative;
  /* border-right: 1px solid #ffbd59; */
}

.nav-content li {
  white-space: nowrap;
  min-height: 41px;
  position: relative;
  font-family: "Inter", sans-serif;
}

.nav-content ul li a {
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-decoration: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1.2px;
  margin-top: 1.2px;
  cursor: pointer;
  color: #ffffff;
  width: 100%;
  position: relative;
}

.nav-content li a span {
  width: 90%;
  border-radius: 5px;
  padding-left: 5%;
  color: #ffffff;
}

.nav-content a span:hover {
  background-color: rgb(70, 69, 69);
  color: #ffffff;
}

.nav-content .topMenu li:nth-child(4) {
  border-bottom: 1px solid #bcbfc6;
  padding-bottom: 8px;
}
.nav-content .topMenu li:nth-child(5) {
  margin-top: 4px;
}

.nav-content li img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  margin-left: 2px;
}

.nav-content .topMenu {
  height: 60%;
}

.burgerMenu {
  position: relative;
  display: flex;
}

.burgerMenu div:nth-child(1) {
  height: 10vh;
  position: relative;
}
.burgerMenu div:nth-child(1) img {
  margin: 0.5%;
  margin-top: 0.7%;
  height: 30px;
  width: 30px;
  position: fixed;
  align-items: center;
  display: flex;
}
.nav-content .logo1 {
  height: 10vh;
  position: relative;
}

.nav-content .logo1 img {
  margin-top: 0.5%;
  width: 14.5%;
  height: 6%;
  padding-left: 48.5px;
  position: fixed;
  align-items: center;
  display: flex;
}

.nav-content:hover .logo1 {
  visibility: visible;
}

.nav-content:not(:hover) .logo1 {
  visibility: hidden;
}

.nav-content::-webkit-scrollbar {
  display: none;
}

.nav-content:hover {
  width: 250px;
  max-width: 250px;
  transition: 0.3s ease;
}
#burgerMenu {
  display: none;
  margin-left: 5px;
}
.MOBILE {
  display: none !important;
}
.WEB {
  display: block !important;
}

@media (max-width: 768px) {
  .header {
    height: 50px;
  }
  .nav-content {
    width: 0px;
  }

  .header .div1 h3 {
    margin-top: 5%;
  }

  #burgerMenu {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .content-Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    font-family: "Inter", sans-serif;
    position: relative;
  }

  .containerChild {
    height: 100%;
    width: 100%;
    margin: 0px;
  }

  .header .div3 {
    position: absolute;
    right: 2%;
    margin-top: 2%;
  }

  .header .div2 {
    width: 60%;

    align-items: center;
    display: none;
  }
  .div2 .searchBar {
    margin-left: 40%;
    border-radius: 5px;
    border: none;
  }

  .searchIcon {
    display: none;
  }
  .div2 .searchBar input {
    width: 100%;
    height: 90%;
    text-align: left;
    margin-left: 5px;
    padding: 1%;
  }
  .WEB {
    display: none !important;
  }
  .MOBILE {
    display: block !important;
  }
}
</style>
